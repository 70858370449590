<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>LINE 應用中心</b-breadcrumb-item>
            <b-breadcrumb-item active>圖文選單</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">圖文選單</h4>

      <div
        class="
          d-flex
          flex-wrap
          mb-3
          justify-content-start justify-content-lg-end
        "
      >
        <b-button
          v-if="showSyncButton"
          style="background-color: #00b900"
          class="mb-2 mb-xl-0 ml-2 py-2"
          variant="primary"
          @click="syncFromLine"
        >
          <i class="fa fa-rotate-left" aria-hidden="true"></i>同步 LINE 選單
        </b-button>
        <b-button
          v-if="$permissions.has($permissions.consts.LINE_MENU_CREATE)"
          class="mb-2 mb-xl-0 ml-2 py-2"
          variant="primary"
          :to="{ name: 'RichMenuCreate' }"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>新增圖文選單
        </b-button>
      </div>
      <div class="d-flex justify-content-end">
        <a
          v-if="showBatchScheduleBtn"
          href="#"
          class="btn pr-0 button"
          :class="{ disabled: !selectedItems.length }"
          @click="batchSetSchedule"
        >
          <i
            class="fa fa-calendar-check-o"
            :class="{ 'text-primary': !!selectedItems.length }"
          ></i>
          線上預約
        </a>
        <a
          v-if="showBatchPublishBtn"
          href="#"
          class="btn pr-0 button"
          :class="{ disabled: !selectedItems.length }"
          @click="batchPublish()"
        >
          <i
            class="fa fa-arrow-circle-up"
            :class="{ 'text-primary': !!selectedItems.length }"
          ></i>
          立即上線
        </a>
        <a
          v-if="showDeleteBtn"
          href="#"
          class="btn pr-0 button"
          :class="{ disabled: !selectedItems.length }"
          @click="batchDelete"
        >
          <i
            class="fa fa-trash-o"
            :class="{ 'text-primary': !!selectedItems.length }"
          ></i>
          刪除
        </a>
      </div>
      <b-tabs v-model="currentTabIndex" @input="handleChangeTab">
        <b-tab v-for="tab in tabs" :title="tab.title" :key="tab.id">
          <b-card-text>
            <div class="row">
              <div class="col-12">
                <b-table
                  striped
                  hover
                  responsive
                  :items="messages"
                  :fields="fields"
                  :busy="isFetch"
                  @sort-changed="handleSort"
                >
                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>

                  <template #cell(checkbox)="data">
                    <b-form-checkbox
                      @change="clickCheckbox($event, data)"
                    ></b-form-checkbox>
                  </template>

                  <template #cell(image_url)="data">
                    <img :src="data.item.image_url" class="thumb-image" />
                  </template>

                  <template #cell(title)="data">
                    <router-link
                      :to="{
                        name: 'RichMenuShow',
                        params: {
                          menuID: data.item.id,
                        },
                      }"
                    >
                      <!-- <b-img
                        fluid
                        rounded="0"
                        src="https://picsum.photos/250/250/?image=54"
                        alt="Image 1"
                        class="mr-1"
                      ></b-img> -->
                      {{ data.item.title }}
                    </router-link>
                  </template>

                  <template #cell(content)="data">
                    <ul>
                      <li
                        v-for="(i, idx) in data.item.content.actions"
                        :key="idx"
                      >
                        {{ i.label }}：{{ i.linkUri }}
                      </li>
                    </ul>
                  </template>

                  <template #cell(use_at)="data">
                    <div class="mb-1">
                      {{
                        data.item.start_at
                          ? format(
                              new Date(data.item.start_at),
                              "yyyy-MM-dd HH:mm"
                            )
                          : ""
                      }}
                      {{ data.item.start_at && data.item.end_at ? " ~ " : "" }}
                      {{
                        data.item.end_at
                          ? format(
                              new Date(data.item.end_at),
                              "yyyy-MM-dd HH:mm"
                            )
                          : ""
                      }}
                    </div>
                  </template>

                  <template #cell(actions)="data">
                    <b-button
                      class="mr-2"
                      variant="inverse-success"
                      @click="preview(data.item)"
                    >
                      <span class="mdi mdi-magnify mr-1"></span> 預覽
                    </b-button>
                    <b-button
                      class="mr-2"
                      v-if="
                        $permissions.has(
                          $permissions.consts.LINE_MENU_CREATE
                        ) && data.item.build_by_system
                      "
                      variant="inverse-info"
                      :to="{
                        name: 'RichMenuCreate',
                        query: { copyFrom: data.item.id },
                      }"
                    >
                      <span class="mdi mdi-content-copy mr-1"></span> 複製
                    </b-button>
                    <b-button
                      class="mr-2"
                      variant="inverse-primary"
                      :to="{
                        name: 'RichMenuShow',
                        params: { menuID: data.item.id },
                      }"
                    >
                      <span class="mdi mdi-eye mr-1"></span>查看
                    </b-button>
                    <b-button
                      class="mr-2"
                      v-if="
                        $permissions.has(
                          $permissions.consts.LINE_MENU_MODIFY
                        ) && data.item.build_by_system
                      "
                      variant="inverse-warning"
                      :to="{
                        name: 'RichMenuEdit',
                        params: { menuID: data.item.id },
                      }"
                    >
                      <span class="mdi mdi-lead-pencil mr-1"></span>編輯
                    </b-button>
                    <b-button
                      class="mr-2"
                      v-if="
                        $permissions.has(
                          $permissions.consts.LINE_MENU_DELETE
                        ) &&
                        data.item.build_by_system &&
                        currentTabIndex === RICH_MENU_STATUS.PUBLISHED
                      "
                      variant="inverse-danger"
                      @click="unpublish(data.item)"
                    >
                      <span class="mdi mdi-delete mr-1"></span>下線
                    </b-button>
                    <b-button
                      class="mr-2"
                      v-if="showDeleteBtn"
                      variant="inverse-danger"
                      @click="deleteMenu(data.item)"
                    >
                      <span class="mdi mdi-delete mr-1"></span>刪除
                    </b-button>

                    <b-dropdown
                      v-if="
                        data.item.build_by_system &&
                        currentTabIndex === RICH_MENU_STATUS.PUBLISHED
                      "
                      variant="secondary"
                    >
                      <template slot="button-content">
                        <i class="mdi mdi-menu"></i>
                      </template>
                      <b-dropdown-item
                        v-if="canShowSetDefault(data.item.status)"
                        @click="setDefault(data.item)"
                      >
                        設定預設選單
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="canShowBindCustomer(data.item.status)"
                        :to="{
                          name: 'RichMenuCustomerList',
                          params: { menuID: data.item.id },
                        }"
                      >
                        會員綁定
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </div>

            <CustomPagination
              :currentPage="query.page"
              :totalRows="total"
              :perPage="query.per_page"
              @page-change="handlePageChange"
              @per-page-change="handlePerPageChange"
            />
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>

    <b-modal ref="previewModal" hide-footer centered title="內容設定預覽">
      <div class="d-flex justify-content-center">
        <Preview
          :image-url="selectedMenu.image_url"
          :content="selectedMenu.content"
        ></Preview>
      </div>
    </b-modal>
  </div>
</template>

<script>
import richMenuApi from "@/apis/richmenu";
import { mapState } from "vuex";
import { format } from "date-fns";
import { BTable } from "bootstrap-vue";
import { RICH_MENU_STATUS } from "@/consts";
import Preview from "@/components/Dashboard/OA/RichMenu/Preview";

import "bootstrap-vue/dist/bootstrap-vue.css";

import checkPermission from "@/mixins/checkPermission";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { paginationMixin } from "@/mixins/pagination";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";

export default {
  mixins: [checkPermission, paginationMixin],
  components: {
    CustomPagination,
    BTable,
    Preview,
  },
  data() {
    return {
      isFetch: false,
      messages: [],
      tabs: [
        {
          id: RICH_MENU_STATUS.DRAFT,
          title: "草稿",
        },
        {
          id: RICH_MENU_STATUS.RESERVE,
          title: "已預約",
        },
        {
          id: RICH_MENU_STATUS.PUBLISHED,
          title: "使用中",
        },
        {
          id: RICH_MENU_STATUS.UNPUBLISHED,
          title: "已下線",
        },
      ],
      baseFields: [
        {
          key: "image_url",
          label: "圖片",
          thClass: "text-left",
        },
        {
          key: "is_default",
          label: "預設選單",
          thClass: "text-left",
          formatter: (value) => {
            return value ? "是" : "否";
          },
        },
        {
          key: "title",
          label: "標題",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "use_at",
          label: "執行時間",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "actions",
          label: "管理",
          thClass: "text-left",
        },
      ],
      selectedItems: [],
      currentTabIndex:
        "tab" in this.$route.query
          ? parseInt(this.$route.query.tab)
          : RICH_MENU_STATUS.DRAFT,
      total: 0,
      selectedMenu: {},
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        sortBy: "id",
        sortDesc: "desc",
        tab: RICH_MENU_STATUS.DRAFT,
      },
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    showSyncButton() {
      return this.currentTabIndex === RICH_MENU_STATUS.PUBLISHED
    },
    showBatchScheduleBtn() {
      return this.currentTabIndex === RICH_MENU_STATUS.DRAFT;
    },
    showBatchPublishBtn() {
      return (
        this.currentTabIndex === RICH_MENU_STATUS.DRAFT ||
        this.currentTabIndex === RICH_MENU_STATUS.RESERVE
      );
    },
    showDeleteBtn() {
      return (
        this.$permissions.has(this.$permissions.consts.LINE_MENU_DELETE) &&
        this.currentTabIndex !== RICH_MENU_STATUS.PUBLISHED
      );
    },
    RICH_MENU_STATUS() {
      return RICH_MENU_STATUS;
    },
    fields() {
      if (
        this.currentTabIndex === RICH_MENU_STATUS.DRAFT ||
        this.currentTabIndex === RICH_MENU_STATUS.RESERVE
      ) {
        let fields = [
          {
            key: "checkbox",
            label: "選擇",
            thClass: "text-left",
          },
        ];

        return fields.concat(this.baseFields);
      }

      return this.baseFields;
    },

    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },

  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.fetchRichMenus();
    },
  },

  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },

  mounted() {
    this.fetchRichMenus();
    this.initialized = true;
  },

  methods: {
    format,
    async fetchRichMenus() {
      this.isFetch = true;

      try {
        const params = {
          status: this.currentTabIndex,
          ...this.query,
        };

        const { data } = await richMenuApi.getRichMenus(params);

        this.total = data.meta.total;
        this.messages = data.data;
        this.selectedItems = [];
      } catch (error) {
        console.log("error", error);
      }
      this.isFetch = false;
    },

    handleChangeTab(tab) {
      this.selectedItems = [];
      this.query.tab = this.tabs[tab].id;
      this.handlePageReset();
    },

    handleSort(ctx) {
      this.query.sortBy = ctx.sortBy;
      this.query.sortDesc = ctx.sortDesc ? "desc" : "asc";
      this.handlePageReset();
    },
    canShowBindCustomer(status) {
      return (
        this.$permissions.has(this.$permissions.consts.LINE_MENU_MODIFY) &&
        status == RICH_MENU_STATUS.PUBLISHED
      );
    },
    canShowSchedule(status) {
      return (
        this.$permissions.has(this.$permissions.consts.LINE_MENU_MODIFY) &&
        status == RICH_MENU_STATUS.DRAFT
      );
    },
    canShowPublish(status) {
      return (
        this.$permissions.has(this.$permissions.consts.LINE_MENU_MODIFY) &&
        [RICH_MENU_STATUS.DRAFT, RICH_MENU_STATUS.RESERVE].indexOf(status) != -1
      );
    },
    canShowSetDefault(status) {
      return (
        this.$permissions.has(this.$permissions.consts.LINE_MENU_MODIFY) &&
        status == RICH_MENU_STATUS.PUBLISHED
      );
    },

    async actionFrame(
      confirmTitle,
      confirmMessage,
      doFunction,
      successMessage,
      failMessage
    ) {
      if (confirmTitle) {
        const result = await this.$swal({
          title: confirmTitle,
          html: confirmMessage,
          type: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "取消",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "確認",
          reverseButtons: true,
        });
        if (!result.value) {
          return;
        }
      }

      this.isFetch = true;

      try {
        await doFunction();
        await this.fetchRichMenus();

        this.$swal.fire({ type: "success", title: successMessage });
      } catch (error) {
        console.log(error);
        this.$swal.fire({ type: "error", title: failMessage });
      }
      this.isFetch = false;
    },
    setSchedule(richmenu) {
      this.actionFrame(
        "預約上線",
        `
          請問確定將${richmenu.title}預約上線？<br/>
          上線時間到之後，選單將自動生效。
        `,
        () => richMenuApi.setSchedule(richmenu.id),
        "加入排程成功",
        "加入排程失敗"
      );
    },
    unpublish(richmenu) {
      this.actionFrame(
        "下線選單",
        `
          請問確定立即下線${richmenu.title}？<br/>
          下線後將無法回覆，使用此選單的會員亦將無法使用此選單。
        `,
        () => richMenuApi.unpublish(richmenu.id),
        "下線成功",
        "下線失敗"
      );
    },
    publish(richmenu) {
      this.actionFrame(
        "立即上線",
        `
          請問確定將${richmenu.title}立即上線？<br/>
          上線後，選單將自動生效。
        `,
        () => richMenuApi.publish(richmenu.id),
        "上線成功",
        "上線失敗"
      );
    },
    setDefault(richmenu) {
      this.actionFrame(
        null,
        null,
        () => richMenuApi.setDefault(richmenu.id),
        "設定成功",
        "設定失敗"
      );
    },
    deleteMenu(richmenu) {
      this.actionFrame(
        "刪除選單",
        `
          請問確定立即刪除${richmenu.title}？<br/>
          刪除後將無法回覆，使用此選單的會員亦將無法使用此選單。
        `,
        () => richMenuApi.removeRichMenu(richmenu.id),
        "刪除成功",
        "刪除失敗"
      );
    },
    async syncFromLine() {
      const result = await this.$swal({
        title: "同步 LINE 選單",
        text: "本系統將偵測 LINE 目前使用中的選單，同步一份至本後台，不會更新任何資料至 LINE 平台",
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "取消",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "確認",
        reverseButtons: true,
      });
      if (!result.value) {
        return;
      }

      this.isFetch = true;

      try {
        const resp = await richMenuApi.syncFromLine();

        let successMessage = "同步成功";

        if (resp.data.data.sync_count) {
          await this.fetchRichMenus();
        } else {
          successMessage = "同步成功(無選單)";
        }

        this.$swal.fire({ type: "success", title: successMessage });
      } catch (error) {
        console.log(error);
        this.$swal.fire({ type: "error", title: "同步失敗" });
      }
      this.isFetch = false;
    },
    clickCheckbox(event, data) {
      if (event) {
        this.selectedItems.push(data.item);
      } else {
        this.selectedItems = this.selectedItems.filter(
          (item) => item.id !== data.item.id
        );
      }
    },
    batchSetSchedule() {
      const titles = this.selectedItems.map((item) => item.title).join(", ");

      this.actionFrame(
        "預約上線",
        `
          請問確定將${titles}預約上線？<br/>
          上線時間到之後，選單將自動生效。
        `,
        () =>
          richMenuApi.batchSetSchedule({
            ids: this.selectedItems.map((item) => item.id),
          }),
        "加入排程成功",
        "加入排程失敗"
      );
    },
    batchPublish() {
      const titles = this.selectedItems.map((item) => item.title).join(", ");

      this.actionFrame(
        "立即上線",
        `
          請問確定將${titles}立即上線？<br/>
          上線後，選單將自動生效。
        `,
        () =>
          richMenuApi.batchPublish({
            ids: this.selectedItems.map((item) => item.id),
          }),
        "上線成功",
        "上線失敗"
      );
    },
    batchDelete() {
      const titles = this.selectedItems.map((item) => item.title).join(", ");

      this.actionFrame(
        "刪除選單",
        `
          請問確定立即刪除${titles}？<br/>
          刪除後將無法回覆，使用此選單的會員亦將無法使用此選單。
        `,
        () =>
          richMenuApi.batchDelete({
            ids: this.selectedItems.map((item) => item.id),
          }),
        "刪除成功",
        "刪除失敗"
      );
    },
    preview(menu) {
      this.selectedMenu = menu;
      this.$refs.previewModal.show();
    },
  },
};
</script>

<style lang="scss"></style>
